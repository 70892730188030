<template>
  <div>
    <div class="mb-5">
      <v-text-field
        v-model="code"
        class="c-input-xs"
        type="text"
        :label="placeholder"
        autofocus
        dense
        outlined
        clearable
        hide-details
        @keyup.enter="inputScan"
      ></v-text-field>
    </div>

    <div class="mb-5">
      <div>
        <div class="d-flex align-center justify-space-between mb-3">
          <div
            :class="{
              'error--text text-decoration-underline': [1, 7].includes(step),
            }"
          >
            <span class="font-weight-medium"
              >{{ $t("labels.basket_code") }}:</span
            >
            {{ basket_code }}
          </div>
          <div>
            <span class="font-weight-medium mr-1"
              >{{ $t("labels.checked") }}:</span
            >
            <span class="info--text">{{ receiptItem.receipted_quantity }}</span
            >/
            <span class="success--text"
              >{{ receiptItem.request_quantity }}
            </span>
          </div>
        </div>

        <div
          class="mb-3"
          :class="{
            'error--text text-decoration-underline': [2, 7].includes(step),
          }"
        >
          <span class="font-weight-medium">{{ $t("labels.uid") }}:</span>
          <v-chip small v-for="uid in uids" :key="uid" class="ml-1">{{
            uid
          }}</v-chip>
        </div>

        <div class="d-flex align-center justify-space-between mb-3">
          <div :class="{ 'error--text text-decoration-underline': step === 3 }">
            <span class="font-weight-medium">{{ $t("labels.d_size") }}:</span>
            {{ d_size }}
          </div>
          <div :class="{ 'error--text text-decoration-underline': step === 4 }">
            <span class="font-weight-medium">{{ $t("labels.r_size") }}:</span>
            {{ r_size }}
          </div>
          <div :class="{ 'error--text text-decoration-underline': step === 5 }">
            <span class="font-weight-medium">{{ $t("labels.c_size") }}:</span>
            {{ c_size }}
          </div>
          <div :class="{ 'error--text text-decoration-underline': step === 6 }">
            <span class="font-weight-medium">{{ $t("labels.weight_1") }}:</span>
            {{ weight }}
          </div>
        </div>

        <v-row>
          <v-col cols="3" v-if="receiptItem.url_images">
            <ImageViewer :url="receiptItem.url_images" height="106px" />
          </v-col>
          <v-col :cols="receiptItem.url_images ? 9 : 12">
            <div class="mb-3">
              <span class="font-weight-medium">{{ $t("labels.name") }}:</span>
              {{ receiptItem.name || "" }}
            </div>
            <div class="mb-3">
              <span class="font-weight-medium"
                >{{ $t("labels.goods_description") }}:</span
              >
              {{ receiptItem.description || "" }}
            </div>
            <div class="mb-3">
              <span class="font-weight-medium">{{ $t("labels.size") }}:</span>
              {{ receiptItem.goods_size || "" }}
            </div>
          </v-col>
        </v-row>

        <div class="mt-5" v-if="!basket_code && !receiptItem.id">
          <v-btn small block color="warning" @click="showDialogNoGoods">
            {{ $t("labels.no_goods") }}
          </v-btn>
        </div>

        <div
          class="mt-5"
          v-if="
            checkPermission(['goods_receipt_has_stamp_multiple']) &&
            !!basket_code
          "
        >
          <v-btn
            small
            block
            color="purple"
            outlined
            @click="showDialogScanMultiple"
          >
            {{ $t("labels.scan_multiple") }}
          </v-btn>
        </div>
      </div>
    </div>

    <v-dialog v-model="dialogNoGoods" persistent max-width="350px">
      <ConfirmNoGoods v-if="dialogNoGoods" @cancel="hideDialogNoGoods" />
    </v-dialog>

    <v-dialog v-model="dialogScanMultiple" persistent max-width="400px">
      <ScanMultipleStamp
        v-if="dialogScanMultiple"
        @cancel="hideDialogScanMultiple"
        @onItemScanned="onItemScanned"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "Fast",
  components: {
    ImageViewer: () => import("@/components/goods/ImageViewer"),
    ConfirmNoGoods: () => import("@/components/goods_receipt/ConfirmNoGoods"),
    ScanMultipleStamp: () =>
      import("@/components/goods_receipt/ScanMultipleStamp"),
  },
  props: {},
  data: () => ({
    isLoading: false,
    code: null,
    d_size: null,
    r_size: null,
    c_size: null,
    weight: null,
    basket_code: null,
    receiptItem: {},
    uids: [],
    dialogNoGoods: false,
    dialogScanMultiple: false,
  }),
  computed: {
    step() {
      if (!this.basket_code) {
        return 1;
      } else if (!this.receiptItem || !this.receiptItem.id) {
        return 2;
      } else if (this.receiptItem.id && !this.d_size) {
        return 3;
      } else if (this.receiptItem.id && !this.r_size) {
        return 4;
      } else if (this.receiptItem.id && !this.c_size) {
        return 5;
      } else if (this.receiptItem.id && !this.weight) {
        return 6;
      } else {
        return 7;
      }
    },
    placeholder() {
      switch (this.step) {
        case 1:
          return this.$t("labels.basket_code");
        case 2:
          return this.$t("labels.uid");
        case 3:
          return this.$t("labels.d_size");
        case 4:
          return this.$t("labels.r_size");
        case 5:
          return this.$t("labels.c_size");
        case 6:
          return this.$t("labels.weight_1");
        case 7:
          return this.$t("labels.uid_or_basket");
        default:
          return "--- ERROR ---";
      }
    },
  },
  mounted() {
    if (
      window.me &&
      window.me.working &&
      window.me.working.receipt &&
      window.me.working.receipt_item
    ) {
      const working = JSON.parse(window.me.working.receipt_item);
      if (working.uids && working.uids.length > 0) {
        this.d_size = working.d_size;
        this.r_size = working.r_size;
        this.c_size = working.c_size;
        this.weight = working.weight;
        this.basket_code = working.basket_code;
        this.receiptItem = { ...working.receiptItem };
        this.uids = [...working.uids];
      }
    }
  },
  methods: {
    cancel() {
      this.$emit("cancel");
      this.$emit("refreshData");
    },
    restartAll() {
      this.isLoading = false;
      this.code = null;
      this.d_size = null;
      this.r_size = null;
      this.c_size = null;
      this.weight = null;
      this.basket_code = null;
      this.receiptItem = {};
      this.uids = [];
    },
    showDialogNoGoods() {
      this.dialogNoGoods = true;
    },
    hideDialogNoGoods() {
      this.dialogNoGoods = false;
    },
    showDialogScanMultiple() {
      this.dialogScanMultiple = true;
    },
    hideDialogScanMultiple() {
      this.dialogScanMultiple = false;
    },
    onItemScanned(items) {
      const item = items[0];
      if (
        this.receiptItem &&
        this.receiptItem.sku &&
        this.receiptItem.sku != item.sku
      ) {
        this.code = null;
        this.$vToastify.warning(this.$t("messages.wrong_goods"));
        return false;
      }

      items.forEach((otem) => {
        if (!this.uids.includes(otem.sku_uid)) {
          this.uids.push(otem.sku_uid);
        }
      });

      this.receiptItem = {
        ...item,
        receipted_quantity: item.receipted_quantity + this.uids.length,
      };
      if (!this.weight || !this.d_size || !this.r_size || !this.c_size) {
        this.d_size = this.receiptItem.d_size;
        this.r_size = this.receiptItem.r_size;
        this.c_size = this.receiptItem.c_size;
        this.weight = this.receiptItem.weight;
      }

      document.getElementById("success_sound_player").play();
      this.code = null;
      this.hideDialogScanMultiple();

      this.updateWorking({
        type: "receipt",
        item: JSON.stringify({
          d_size: this.d_size,
          r_size: this.r_size,
          c_size: this.c_size,
          weight: this.weight,
          basket_code: this.basket_code,
          receiptItem: this.receiptItem,
          uids: this.uids,
        }),
      });
    },
    async inputScan() {
      if (!this.code) {
        return false;
      }
      //
      switch (this.step) {
        case 1:
          await this.scanBasket();
          break;
        case 2:
          await this.scanUid();
          break;
        case 3:
          await this.setDSize();
          break;
        case 4:
          await this.setRSize();
          break;
        case 5:
          await this.setCSize();
          break;
        case 6:
          await this.setWeight();
          break;
        case 7:
          if (this.code == this.basket_code) {
            await this.confirmBasketUid();
          } else {
            await this.scanUid();
          }
          break;
        default:
          this.$vToastify.error(" --- ERROR --- ");
          document.getElementById("error_sound_player").play();
          break;
      }
    },
    async scanUid() {
      if (this.uids.includes(this.code)) {
        this.code = null;
        document.getElementById("error_sound_player").play();
        this.$vToastify.error(this.$t("messages.uid_scanned"));
        return false;
      }

      if (this.uids.length > 0) {
        const uid = this.uids[0];
        const [oSku] = uid.split("@");
        if (this.code.includes("@")) {
          const [nSku] = this.code.split("@");
          if (oSku !== nSku) {
            this.code = null;
            this.$vToastify.warning(this.$t("messages.wrong_goods"));
            return false;
          }
        }
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-receipt/v1/get-goods-has-stamp",
          {
            code: this.code,
            group_tracking:
              (this.receiptItem && this.receiptItem.group_tracking) || null,
          }
        );
        if (
          this.receiptItem &&
          this.receiptItem.sku &&
          this.receiptItem.sku != data.sku
        ) {
          this.code = null;
          this.$vToastify.warning(this.$t("messages.wrong_goods"));
          return false;
        }
        this.uids.push(data.sku_uid);
        this.receiptItem = {
          ...data,
          receipted_quantity: data.receipted_quantity + this.uids.length,
        };
        if (!this.weight || !this.d_size || !this.r_size || !this.c_size) {
          this.d_size = this.receiptItem.d_size;
          this.r_size = this.receiptItem.r_size;
          this.c_size = this.receiptItem.c_size;
          this.weight = this.receiptItem.weight;
        }

        document.getElementById("success_sound_player").play();
        this.code = null;
        this.isLoading = false;

        this.updateWorking({
          type: "receipt",
          item: JSON.stringify({
            d_size: this.d_size,
            r_size: this.r_size,
            c_size: this.c_size,
            weight: this.weight,
            basket_code: this.basket_code,
            receiptItem: this.receiptItem,
            uids: this.uids,
          }),
        });
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        document.getElementById("error_sound_player").play();
        this.code = null;
        this.isLoading = false;
      }
    },
    async scanBasket() {
      try {
        const { data } = await httpClient.post("/basket/v1/receipt", {
          code: this.code,
        });
        this.basket_code = data.code;
        this.code = null;
        document.getElementById("success_sound_player").play();
      } catch (e) {
        console.error(e);
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },
    async confirmBasketUid() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post(
          "/goods-receipt/v1/check-basket-and-create-history-has-stamp",
          {
            basket_code: this.basket_code,
            group_tracking: this.receiptItem.group_tracking,
            id_goods: this.receiptItem.id_goods,
            d_size: this.d_size,
            r_size: this.r_size,
            c_size: this.c_size,
            weight: this.weight,
            uids: this.uids,
          }
        );

        document.getElementById("success_sound_player").play();
        this.updateWorking({
          type: "receipt",
          item: null,
        });
        this.restartAll();
      } catch (e) {
        console.error(e);
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },
    async setDSize() {
      if (!Number.isInteger(+this.code) || this.code < 1 || this.code > 500) {
        this.$vToastify.error(this.$t("messages.not_positive_integer"));
        document.getElementById("error_sound_player").play();
        this.code = null;
        return false;
      }
      this.d_size = +this.code;
      this.code = null;
      document.getElementById("success_sound_player").play();
    },
    async setRSize() {
      if (!Number.isInteger(+this.code) || this.code < 1 || this.code > 500) {
        this.$vToastify.error(this.$t("messages.not_positive_integer"));
        document.getElementById("error_sound_player").play();
        this.code = null;
        return false;
      }
      this.r_size = +this.code;
      this.code = null;
      document.getElementById("success_sound_player").play();
    },
    async setCSize() {
      if (!Number.isInteger(+this.code) || this.code < 1 || this.code > 500) {
        this.$vToastify.error(this.$t("messages.not_positive_integer"));
        document.getElementById("error_sound_player").play();
        this.code = null;
        return false;
      }
      this.c_size = +this.code;
      this.code = null;
      document.getElementById("success_sound_player").play();
    },
    async setWeight() {
      if (!Number.isInteger(+this.code) || this.code < 1) {
        this.$vToastify.error(this.$t("messages.not_positive_integer"));
        document.getElementById("error_sound_player").play();
        this.code = null;
        return false;
      }
      this.weight = +this.code;
      this.code = null;
      document.getElementById("success_sound_player").play();
      this.updateWorking({
        type: "receipt",
        item: JSON.stringify({
          d_size: this.d_size,
          r_size: this.r_size,
          c_size: this.c_size,
          weight: this.weight,
          basket_code: this.basket_code,
          receiptItem: this.receiptItem,
          uids: this.uids,
        }),
      });
    },
  },
};
</script>

<style scoped></style>
